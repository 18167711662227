/* eslint-disable */
const {cookies, cookiesConsent, environment} = require('../constants-enumerate');
import Cookies from 'js-cookie';

if (Cookies.get(cookiesConsent.ANALYTICS) === undefined) {
  Cookies.set(cookiesConsent.ANALYTICS, 'true', cookies.CONFIG);
}
if (Cookies.get(cookiesConsent.TARGETING) === undefined) {
  Cookies.set(cookiesConsent.TARGETING, 'true', cookies.CONFIG);
}

if (!navigator.globalPrivacyControl || !Cookies.get(cookies.WDIO_SUPPRESS_PIXELS) || (!environment.IN_APP && Cookies.get(cookiesConsent.ANALYTICS) === 'true' && Cookies.get(cookiesConsent.TARGETING) === 'true')) {
  /**
   *
   * Google Tag Manager
   */
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WFS38CD');

  const bodyTag = document.getElementsByTagName('body')[0];
  const noScript = document.createElement('noscript');
  const gtmIframe = document.createElement('iframe');
  gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-WFS38CD';
  gtmIframe.height = '0';
  gtmIframe.width = '0';
  gtmIframe.style = 'display:none;visibility:hidden';
  bodyTag.appendChild(noScript);
  noScript.appendChild(gtmIframe);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);};
  gtag('consent', 'update', {
   'ad_storage': 'granted',
   'analytics_storage': 'granted'
  });
}
